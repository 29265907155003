import Navbar from "../Dashboard/Navbar/Navbar";
import InfoHeader from "../Dashboard/InfoHeader";
import { useSelector } from "react-redux";
import axios from "../../utils/axios";
import { getUserSID ,getUserId , getCurrentUserFromCookies  , updateInfoInCurrentUser} from "../../utils/helpers";
import toast from "react-hot-toast";
import React, { useEffect } from "react";

const Layout = ({ children, title }) => {
  const { sidebarCollapse } = useSelector((state) => state.userChange);

  
  
  // live update province with QBO check everytime user open app or switch
  useEffect(() => {
    axios.get(`/companyinfo/${getUserSID()}`).then((res) => {
      const {CountrySubDivisionCode } = res.data.data.body.legaladdress;
        let updateData = {
          sid: getUserSID(),
          province: CountrySubDivisionCode
        }
        if(CountrySubDivisionCode !== getCurrentUserFromCookies().province){
          axios.put(
            `/update/province`, updateData).then((res) => {
              const { data } = res.data;
              updateInfoInCurrentUser({province:CountrySubDivisionCode})
              
          }).catch((err) => {
            console.log('err',err)
            toast.error('Current province not match with QBO province')
          });
        }
        
    }).catch((err) => {
      console.error('Error getting company info or Token expired Please reconnect again')
      // toast.error('Error getting company info or Token expired Please reconnect again')
    });
  },[])

 
  return (
    <section className={`wrapper ${sidebarCollapse ? "mini" : ""}`}>
      <aside className="mainSide">
        <Navbar />
      </aside>
      <main>
        <InfoHeader title={title} />
        {children}
      </main>
    </section>
  );
};

export default Layout;
