export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_LINK_VERIFIED = "USER_LINK_VERIFIED";

export const USER_CHANGE_REQUEST = "USER_CHANGE_REQUEST";
export const USER_CHANGE_SUCCESS = "USER_CHANGE_SUCCESS";
export const USER_CHANGE_FAIL = "USER_CHANGE_FAIL";

export const USER_SIDEBAR_COLLAPSE = "USER_SIDEBAR_COLLAPSE";
export const USER_SIDEBAR_EXPAND = "USER_SIDEBAR_EXPAND";

export const FULL_REFRESH_FAIL = "FULL_REFRESH_FAIL";
export const FULL_REFRESH_REQUEST = "FULL_REFRESH_REQUEST";
export const FULL_REFRESH_SUCCESS = "FULL_REFRESH_SUCCESS";

export const USER_2FA_REQUEST = "USER_2FA_REQUEST";
export const USER_2FA_SUCCESS = "USER_2FA_SUCCESS";
export const USER_2FA_FAIL = "USER_2FA_FAIL";

export const USER_OPT_REQUEST = "USER_OPT_REQUEST";
export const USER_OPT_SUCCESS = "USER_OPT_SUCCESS";
export const USER_OPT_FAIL = "USER_OPT_FAIL";

export const USER_LOGO_REQUEST = "USER_LOGO_REQUEST";
export const USER_LOGO_SUCCESS = "USER_LOGO_SUCCESS";
export const USER_LOGO_FAIL = "USER_LOGO_FAIL";

export const USER_COMPANY_LOGO_CHANGE = "USER_COMPANY_LOGO_CHANGE";

export const USER_LOGIN_AUTH_REQUEST = "USER_LOGIN_AUTH_REQUEST";
export const USER_LOGIN_AUTH_SUCCESS = "USER_LOGIN_AUTH_SUCCESS";
export const USER_LOGIN_AUTH_FAIL = "USER_LOGIN_AUTH_FAIL";
export const USER_LOGIN_AUTH_EMPTY = "USER_LOGIN_AUTH_EMPTY";

export const USER_PROFILE_INFO_FAIL = "USER_PROFILE_INFO_FAIL";
export const USER_PROFILE_INFO_SUCCESS = "USER_PROFILE_INFO_SUCCESS";
export const USER_PROFILE_INFO_REQUEST = "USER_PROFILE_INFO_REQUEST";

export const USER_PROFILE_INFO_CHANGE_REQUEST =
  "USER_PROFILE_INFO_CHANGE_REQUEST";
export const USER_PROFILE_INFO_CHANGE_SUCCESS =
  "USER_PROFILE_INFO_CHANGE_SUCCESS";
export const USER_PROFILE_INFO_CHANGE_FAIL = "USER_PROFILE_INFO_CHANGE_FAIL";
export const USER_PROFILE_INFO_CHANGE_EMPTY = "USER_PROFILE_INFO_CHANGE_EMPTY";
