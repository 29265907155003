export const MATTER_CLIENT_REQUEST = "MATTER_CLIENT_REQUEST";
export const MATTER_CLIENT_SUCCESS = "MATTER_CLIENT_SUCCESS";
export const MATTER_CLIENT_FAIL = "MATTER_CLIENT_FAIL";

export const CLIENT_DETAILS_FROM_FILE_REQUEST =
  "CLIENT_DETAILS_FROM_FILE_REQUEST";
export const CLIENT_DETAILS_FROM_FILE_SUCCESS =
  "CLIENT_DETAILS_FROM_FILE_SUCCESS";
export const CLIENT_DETAILS_FROM_FILE_FAIL = "CLIENT_DETAILS_FROM_FILE_FAIL";

export const CLEAR_CLIENT_DETAILS_FROM_FILE_REQUEST =
  "CLEAR_CLIENT_DETAILS_FROM_FILE_REQUEST";
export const CLEAR_CLIENT_DETAILS_FROM_FILE_FAIL =
  "CLEAR_CLIENT_DETAILS_FROM_FILE_FAIL";
