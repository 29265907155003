export const dropdownCredit = [
    {label: "30300 - Spouse or common-law partner amount", value: '30300'},
    {label: "30425 - Canada caregiver amount for spouse or common-law partner, or eligible dependant age 18 or older", value: '30425'},
    {label: "30450 - Canada caregiver amount for other infirm dependants age 18 or older ", value: '30450'},
    {label: "30500 - Canada caregiver amount for infirm children under 18 years of age ", value: '30500'},
    {label: "31200 - Employment insurance premiums:  on self-employment and other eligible earnings ", value: '31200'},
    {label: "31200 - Volunteer firefighters' amount", value: '31220'},
    {label: "31240 - Search and rescue volunteers' amount", value: '31240'},
    {label: "31270 - Home buyers' amount ", value: '31270'},
    {label: "31285 - Home accessibility expenses", value: '31285'},
    {label: "31300 - Adoption expenses ", value: '31300'},
    {label: "31350 - Digital news subscription expenses", value: '31350'},
    {label: "31400 - Pension income amount", value: '31400'},
    {label: "31600 - Disability amount (for self)", value: '31600'},
    {label: "31800 - Disability amount transferred from a dependant ", value: '31800'},
    {label: "31900 - Interest paid on your student loans ", value: '31900'},
    {label: "32300 - Your tuition, education, and textbook amounts ", value: '32300'},
    {label: "32400 - Tuition amount transferred from a child or grandchild ", value: '32400'},
    {label: "32600 - Amounts transferred from your spouse or common-law partner ", value: '32600'},
    {label: "33099 - Medical expenses for self, spouse or common-law partner, and your dependent children born in 2003 or later", value: '33099'},
    {label: "33199 - Allowable amount of medical expenses for other dependants", value: '33199'},
    {label: "34900 - Donations and gifts", value: '34900'},
    {label: "58080 - Ontario Spouse or common-law partner amount: Base amount", value: '58080'},
    {label: "58185 - Ontario caregiver amount (use Worksheet ON428)", value: '58185'},
    {label: "58440 - Ontario Disability amount for self", value: '58440'},//doubt
    {label: "58480 - Ontario Disability amount transferred from a dependant (use Worksheet ON428)", value: '58480'},
    {label: "58440 - Ontario Your unused tuition and education amounts (attach Schedule ON(S11))", value: '58440'}, //doubt same number
    {label: "58480 - Ontario Amounts transferred from your spouse or common-law partner (attach Schedule ON(S2))", value: '58480'},
    {label: "58800 - Ontario Medical expenses", value: '58800'},
    {label: "30300 - Ontario Donations and gifts", value: '30300'}, //doubt
]

export const dropdownDeductions = [
    {label: "20700 - Registered pension plan deduction", value: '20700'},
    {label: "20800 - RRSP deduction", value: '20800'},
    {label: "20810 - Pooled registered pension plan (PRPP) employer contributions", value: '20810'},
    {label: "21000 - Deduction for elected split-pension amount", value: '21000'},
    {label: "21200 - Annual union, professional, or like dues", value: '21200'},
    {label: "21300 - Universal child care benefit repayment", value: '21300'},
    {label: "21400 - Child care expenses ", value: '21400'},
    {label: "21500 - Disability supports deduction ", value: '21500'},
    {label: "21700 - Business investment loss", value: '21700'},
    {label: "21900 - Moving expenses ", value: '21900'},
    {label: "22000 - Support payments made", value: '22000'},
    {label: "22100 - Carrying charges and interest expenses", value: '22100'},
    {label: "22400 - Exploration and development expenses ", value: '22400'},
    {label: "22900 - Other employment expenses ", value: '22900'},
    {label: "23100 - Clergy residence deduction ", value: '23100'},
    {label: "23200 - Other deduction", value: '23200'},
    {label: "23210 - Federal COVID 19 benefits repayment (box 201 of all federal t4A slips) ", value: '23210'},
    {label: "23500 - Social benefits repayment ", value: '23500'},
    {label: "24400 - Canadian Forces personnel and police deduction", value: '24400'},
    {label: "24900 - Security options deductions ", value: '24900'},
    {label: "25000 - Other payments deduction ", value: '25000'},
    {label: "25100 - Limited partnership losses of other years ", value: '25100'},
    {label: "25200 - Non-capital losses of other years ", value: '25200'},
    {label: "25300 - Net capital losses of other years", value: '25300'},
    {label: "25400 - Capital gains deduction", value: '25400'},
    {label: "25500 - Northern residents deductions", value: '25500'},
    {label: "25600 - Additional Deductions", value: '25600'},
]


