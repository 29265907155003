export const ratioData =[
    {adults: 1 , child : 0 , amount:10382 } ,
    {adults: 2 , child : 1 , amount:14535 } ,
    {adults: 1 , child : 1 , amount:14535 } ,
    {adults: 2 , child : 1 , amount:17649 } ,
    {adults: 1 , child : 2 , amount:17649} ,
    {adults: 3 , child : 0 , amount:18688} ,
    {adults: 4 , child : 0 , amount:22840} ,
    {adults: 3 , child : 1 , amount:21802} ,
    {adults: 2 , child : 2 , amount:20764} ,
    {adults: 1 , child : 3 , amount:20764} ,
    {adults: 5 , child : 0 , amount:26993} ,
    {adults: 4 , child : 1 , amount:25955} ,
    {adults: 3 , child : 2 , amount:24917} ,
    {adults: 2 , child : 3 , amount:23879} ,
    {adults: 1 , child : 4 , amount:23879} ,
    {adults: 6 , child : 0 , amount:31145} ,
    {adults: 5 , child : 1 , amount:30108} ,
    {adults: 4 , child : 2 , amount:29070} ,
    {adults: 3 , child : 3 , amount:28031} ,
    {adults: 2 , child : 4 , amount:26993} ,
    {adults: 1 , child : 5 , amount:26993} ,
    {adults: 7 , child : 0 , amount:34261} ,
    {adults: 6 , child : 1 , amount:33222} ,
    {adults: 5 , child : 2 , amount:32184} ,
    {adults: 4 , child : 3 , amount:31146} ,
    {adults: 3 , child : 4 , amount:30108} ,
    {adults: 2 , child : 5 , amount:29070} ,
    {adults: 1 , child : 6 , amount:29070} ,
    {adults: 8 , child : 0 , amount:38413} ,
    {adults: 7 , child : 1 , amount:37375} ,
    {adults: 6 , child : 2 , amount:36337} ,
    {adults: 5 , child : 3 , amount:35299} ,
    {adults: 4 , child : 4 , amount:34261} ,
    {adults: 3 , child : 5 , amount:33222} ,
    {adults: 2 , child : 6 , amount:32184} ,
    {adults: 1 , child : 7 , amount:32184} ,
 
]