import React from "react";

const FooterDash = () => {
  return (
    <footer className="mainFooter">
      &copy; {new Date().getFullYear()} CloudAct. All rights reserved.
    </footer>
  );
};

export default FooterDash;
