import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="innerFooter">
      (C){new Date().getFullYear()} CloudAct. All rights reserved.
    </footer>
  );
};

export default Footer;
