export const GET_SINGLE_MATTERS_DATA_REQUEST = "GET_SINGLE_MATTERS_DATA_REQUEST";
export const GET_SINGLE_MATTERS_DATA_SUCCESS = "GET_SINGLE_MATTERS_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_DATA_FAIL = "GET_SINGLE_MATTERS_DATA_FAIL";
export const GET_SINGLE_MATTERS_DATA_RESET = "GET_SINGLE_MATTERS_DATA_RESET";


export const GET_SINGLE_MATTERS_COURT_DATA_REQUEST = "GET_SINGLE_MATTERS_COURT_DATA_REQUEST";
export const GET_SINGLE_MATTERS_COURT_DATA_SUCCESS = "GET_SINGLE_MATTERS_COURT_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_COURT_DATA_FAIL = "GET_SINGLE_MATTERS_COURT_DATA_FAIL";
export const GET_SINGLE_MATTERS_COURT_DATA_RESET = "GET_SINGLE_MATTERS_COURT_DATA_RESET";

export const GET_SINGLE_MATTERS_BACKGROUND_DATA_REQUEST = "GET_SINGLE_MATTERS_BACKGROUND_DATA_REQUEST";
export const GET_SINGLE_MATTERS_BACKGROUND_DATA_SUCCESS = "GET_SINGLE_MATTERS_BACKGROUND_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_BACKGROUND_DATA_FAIL = "GET_SINGLE_MATTERS_BACKGROUND_DATA_FAIL";
export const GET_SINGLE_MATTERS_BACKGROUND_DATA_RESET = "GET_SINGLE_MATTERS_BACKGROUND_DATA_RESET";


export const GET_SINGLE_MATTERS_RELATIONSHIP_DATA_REQUEST = "GET_SINGLE_MATTERS_RELATIONSHIP_DATA_REQUEST";
export const GET_SINGLE_MATTERS_RELATIONSHIP_DATA_SUCCESS = "GET_SINGLE_MATTERS_RELATIONSHIP_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_RELATIONSHIP_DATA_FAIL = "GET_SINGLE_MATTERS_RELATIONSHIP_DATA_FAIL";
export const GET_SINGLE_MATTERS_RELATIONSHIP_DATA_RESET = "GET_SINGLE_MATTERS_RELATIONSHIP_DATA_RESET";

export const GET_SINGLE_MATTERS_CHILDREN_DATA_REQUEST = "GET_SINGLE_MATTERS_CHILDREN_DATA_REQUEST";
export const GET_SINGLE_MATTERS_CHILDREN_DATA_SUCCESS = "GET_SINGLE_MATTERS_CHILDREN_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_CHILDREN_DATA_FAIL = "GET_SINGLE_MATTERS_CHILDREN_DATA_FAIL";
export const GET_SINGLE_MATTERS_CHILDREN_DATA_RESET = "GET_SINGLE_MATTERS_CHILDREN_DATA_RESET";

export const GET_SINGLE_MATTERS_EMPLOYMENT_DATA_REQUEST = "GET_SINGLE_MATTERS_EMPLOYMENT_DATA_REQUEST";
export const GET_SINGLE_MATTERS_EMPLOYMENT_DATA_SUCCESS = "GET_SINGLE_MATTERS_EMPLOYMENT_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_EMPLOYMENT_DATA_FAIL = "GET_SINGLE_MATTERS_EMPLOYMENT_DATA_FAIL";
export const GET_SINGLE_MATTERS_EMPLOYMENT_DATA_RESET = "GET_SINGLE_MATTERS_EMPLOYMENT_DATA_RESET";

export const GET_SINGLE_MATTERS_INCOMEBENEFITS_DATA_REQUEST = "GET_SINGLE_MATTERS_INCOMEBENEFITS_DATA_REQUEST";
export const GET_SINGLE_MATTERS_INCOMEBENEFITS_DATA_SUCCESS = "GET_SINGLE_MATTERS_INCOMEBENEFITS_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_INCOMEBENEFITS_DATA_FAIL = "GET_SINGLE_MATTERS_INCOMEBENEFITS_DATA_FAIL";
export const GET_SINGLE_MATTERS_INCOMEBENEFITS_DATA_RESET = "GET_SINGLE_MATTERS_INCOMEBENEFITS_DATA_RESET";


export const GET_SINGLE_MATTERS_ASSETS_DATA_REQUEST = "GET_SINGLE_MATTERS_ASSETS_DATA_REQUEST";
export const GET_SINGLE_MATTERS_ASSETS_DATA_SUCCESS = "GET_SINGLE_MATTERS_ASSETS_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_ASSETS_DATA_FAIL = "GET_SINGLE_MATTERS_ASSETS_DATA_FAIL";
export const GET_SINGLE_MATTERS_ASSETS_DATA_RESET = "GET_SINGLE_MATTERS_ASSETS_DATA_RESET";

export const GET_SINGLE_MATTERS_EXPENSE_DATA_REQUEST = "GET_SINGLE_MATTERS_EXPENSE_DATA_REQUEST";
export const GET_SINGLE_MATTERS_EXPENSE_DATA_SUCCESS = "GET_SINGLE_MATTERS_EXPENSE_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_EXPENSE_DATA_FAIL = "GET_SINGLE_MATTERS_EXPENSE_DATA_FAIL";
export const GET_SINGLE_MATTERS_EXPENSE_DATA_RESET = "GET_SINGLE_MATTERS_EXPENSE_DATA_RESET";

export const GET_SINGLE_MATTERS_DEBT_DATA_REQUEST = "GET_SINGLE_MATTERS_DEBT_DATA_REQUEST";
export const GET_SINGLE_MATTERS_DEBT_DATA_SUCCESS = "GET_SINGLE_MATTERS_DEBT_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_DEBT_DATA_FAIL = "GET_SINGLE_MATTERS_DEBT_DATA_FAIL";
export const GET_SINGLE_MATTERS_DEBT_DATA_RESET = "GET_SINGLE_MATTERS_DEBT_DATA_RESET";

export const GET_SINGLE_MATTERS_OTHER_PERSONS_DATA_REQUEST = "GET_SINGLE_MATTERS_OTHER_PERSONS_DATA_REQUEST";
export const GET_SINGLE_MATTERS_OTHER_PERSONS_DATA_SUCCESS = "GET_SINGLE_MATTERS_OTHER_PERSONS_DATA_SUCCESS";
export const GET_SINGLE_MATTERS_OTHER_PERSONS_DATA_FAIL = "GET_SINGLE_MATTERS_OTHER_PERSONS_DATA_FAIL";
export const GET_SINGLE_MATTERS_OTHER_PERSONS_DATA_RESET = "GET_SINGLE_MATTERS_OTHER_PERSONS_DATA_RESET";

export const GET_MATTER_DATA_REQUEST = "GET_MATTER_DATA_REQUEST";
export const GET_MATTER_DATA_SUCCESS = "GET_MATTER_DATA_SUCCESS";
export const GET_MATTER_DATA_FAIL = "GET_MATTER_DATA_FAIL";
export const GET_MATTER_DATA_RESET = "GET_MATTER_DATA_RESET";