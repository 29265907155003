import SignNewUser from "../components/SignUser/SignNewUser";
import Footer from "../components/Footer";

const CreateAccount = () => {
  return (
    <div className="loginSection">
      <SignNewUser />
      <Footer />
    </div>
  );
};

export default CreateAccount;
